import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const LegalNotice = () => {
  return (
    <Layout>
      <SEO title="Legal Notice" />
      <section className="grid md:grid-cols-12 grid-cols-1">
        <div className="text-padding md:col-start-3 md:col-span-8 mx-auto  px-5 sm:px-12 text-white break-words">
          <h1>Impressum</h1>
          <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
          <p>
            SIGMA Association GbR
            <br />
            Pleicherschulgasse 6<br />
            97070 W&uuml;rzburg
          </p>
          <p>
            <strong>Vertreten durch:</strong>
            <br />
            Sophia Braun
            <br />
            Margarita Eschenbach
            <br />
            Lisa Schejnoha
            <br />
            Anastasia Uljanov
          </p>
          <p>
            <br />
            <strong>Geschäftsführende Gesellschafterin:</strong>
            <br />
            Anastasia Uljanov
          </p>
          <br />
          <h2>Kontakt</h2>
          <p>
            Telefon: 093140466697
            <br />
            E-Mail: cheers@bar-sigma.de
          </p>
          <br />
          <p>
            <h2>Programmierung und Technische Umsetzung</h2>
            <p>
              <a className="text-white" href="mailto:mail@phenkelmann.de">
                mail@phenkelmann.de
              </a>
            </p>
          </p>
          <p>
            <br />
            <br />
            Bildquellen: Adobe Stock, Unsplash.
            <br />
            Designelemente www.clarasdesign.de
          </p>
          <h2>Aufsichtsbeh&ouml;rde</h2>
          <p>
            Stadt W&uuml;rzburg
            <br />
            FA Ordnungsaufgaben
            <br />
            Domstra&szlig;e 1<br />
            97070 W&uuml;rzbug
          </p>
          <p>
            <a
              href="https://www.wuerzburg.de/buerger/aemter-a-z-oeffnungszeiten/index.html?detID=401152"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.wuerzburg.de/buerger/aemter-a-z-oeffnungszeiten/index.html?detID=401152
            </a>
          </p>
          <h2>
            Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
          </h2>
          <p>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
          <h3>Haftung f&uuml;r Inhalte</h3>{' '}
          <p>
            Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG
            f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen
            Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als
            Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder
            gespeicherte fremde Informationen zu &uuml;berwachen oder nach
            Umst&auml;nden zu forschen, die auf eine rechtswidrige
            T&auml;tigkeit hinweisen.
          </p>{' '}
          <p>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab
            dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
            m&ouml;glich. Bei Bekanntwerden von entsprechenden
            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </p>{' '}
          <h3>Haftung f&uuml;r Links</h3>{' '}
          <p>
            Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf
            deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir
            f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr
            &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist
            stets der jeweilige Anbieter oder Betreiber der Seiten
            verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
            Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e
            &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt der
            Verlinkung nicht erkennbar.
          </p>{' '}
          <p>
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
            jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>
          <h3>Urheberrecht</h3>{' '}
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes
            bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors
            bzw. Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r
            den privaten, nicht kommerziellen Gebrauch gestattet.
          </p>{' '}
          <p>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
            wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>
          <p>
            Quelle:{' '}
            <a href="https://www.e-recht24.de" rel="noopener" target="_blank">
              https://www.e-recht24.de
            </a>
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default LegalNotice;
